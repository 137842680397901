<template>
  <div>
    <div class="col-md-12">
      <button type="button" class="btn btn-default btn-lg" v-on:click="loadSales">
        <i class="fa fa-refresh"></i>
      </button>
      <button type="button" class="btn btn-success btn-lg" data-toggle="modal" data-target="#freeSale" v-if="till.customer">
        <i class="fa fa-plus"></i><i class="fa fa-shopping-cart"></i> {{ $t('add_a_sale') }} to {{till.customer.name}}
      </button>
      <br />&nbsp;
      <div class="card">
        <table class="table table-hover table-condensed" id="posListSales">
          <thead>
          <tr>
            <th>{{ $t('hour') }}</th>
            <th>{{ $t('customer') }}</th>
            <th>{{ $t('article') }}</th>
            <th>{{ $t('amount') }}</th>
            <th>{{ $t('payed') }}</th>
            <th><i class="fa fa-print"></i></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="sales.length===0"><td colspan="10">Aucune vente...</td></tr>
          <tr v-for="sale in sales">
            <td>{{sale.date}}</td>
            <td><router-link :to="{name:'customer', params:{customerId: sale.customer.id}}">{{sale.customer.name}}</router-link></td>
            <td><router-link :to="{name:'sale', params:{saleId: sale.id}}">{{sale.title}}</router-link></td>
            <td>
              <money-format :value="sale.amount.amount_incl"
                            locale='en'
                            :currency-code="sale.currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </td>
            <td>
              <router-link :to="{name: 'payments-target', params: {target:'invoice' ,targetId: sale.invoice.id}}" class="btn btn-default btn-xs" v-if="sale.invoice && sale.invoice.id">
                <span v-if="sale.amount.payed_pc>=100"><i class="fa fa-check"></i></span>
                <span v-else>{{sale.amount.payed_pc}} %</span>
              </router-link>
              <router-link :to="{name: 'payments-target', params: {target:'sale' ,targetId: sale.id}}" class="btn btn-default btn-xs" v-else>
                <span v-if="sale.amount.payed_pc>=100"><i class="fa fa-check"></i></span>
                <span v-else>{{sale.amount.payed_pc}} %</span>
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'invoice', params: {invoiceId: sale.invoice.id}}" class="btn btn-default btn-xs" v-if="sale.invoice && sale.invoice.id"><i class="fa fa-file"></i></router-link>
            </td>
            <td><router-link :to="{name: 'sale', params: {saleId: sale.id}}" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i></router-link></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="freeSale" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form method="post" v-on:submit.prevent="createSale">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="myModalLabel"><i class="fa fa-check"></i> {{ $t('add_a_sale') }} <span v-if="till.customer">to {{till.customer.name}}</span></h4>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>{{ $t('hour') }}</label>
                <input class="form-control" type="text" value="" disabled="disabled"/>
              </div><div class="form-group">
              <label>{{ $t('customer') }}</label>
              <input class="form-control" type="text" v-bind:value="till.customer.name" disabled="disabled"/>
            </div>
              <div class="form-group">
                <label>{{ $t('title') }} <a href="javascript:void(0);" style="color:#f00">X</a></label>
                <textarea v-model="newSale.title" v-bind:placeholder="$t('title')" autocomplete="off" class="form-control"></textarea>
              </div>
              <div class="form-group">
                <label>{{ $t('vat_rate') }}</label>
                <select v-model="newSale.vat"  class="form-control">
                  <option v-for="vat in taxes">{{ vat.rate }}</option>
                </select>
              </div>
              <div class="form-group">
                <label>{{ $t('htva') }}</label>
                <div class="input-group">
                  <span class="input-group-addon">€</span>
                  <input type="text" v-model="newSale.amount" placeholder="0,00" class="form-control"/>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('paymodes') }}</label>
                <select v-model="newSale.paymode_id" class="form-control">
                  <option value="">Impayé</option>
                  <option v-for="factoryPaymode in factoryPaymodes" v-bind:value="factoryPaymode.id">{{ $t(factoryPaymode.paymode_ref) }}</option>
                  <option value="isPresent">{{ $t('present') }}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-success">
                <i class="fa fa-shopping-cart"></i> {{ $t('sale') }}
              </button>
              <button type="button" class="btn btn-default" data-dismiss="modal">
                {{ $t('close') }} <i class="fa fa-sign-out"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
    import MoneyFormat from 'vue-money-format'
    export default {
      components: {
        'money-format': MoneyFormat,
      },
      name: "pos-tab-sales",
      data () {
        return {
          newSale: {},
          sales: [],
          interval: null,
        }
      },
      props: {
          till: {
              type: Object,
              required: true,
          },
          loadTill: {
              type: Function,
              required: true,
          },
      },
      mounted : function (){
          this.interval = setInterval(function() {
              if (this.till.id) {
                  this.loadSales();
                  clearInterval(this.interval);
              }
          }.bind(this), 1000);
      },
      computed: {
        factoryPaymodes : function () {
            return this.$parent.factoryPaymodes;
        },
        taxes : function () {
            return this.$parent.taxes;
        },
      },
      methods: {
        loadSales () {
          this.$http.get('pos/till/'+this.till.id+'/sales').then((response) => {
            if (response.ok === true) {
              this.sales = response.data;
            }
          });
        },
        createSale : function () {
          this.newSale.till_id = this.till.id;
          this.newSale.client_id = this.till.customer.id;
          this.newSale.amount_incl = this.newSale.amount * (1+(this.newSale.vat/100));
          this.$http.post('sales', this.newSale).then((response) => {
            if (response.ok === true) {
              this.loadTill();
              $("#freeSale").modal('hide');
            }
          });
        }
      }
    }
</script>
