<template>
  <div id="app">
    <nav class="navbar navbar-light bg-light" style="background-color:#3F6BEC !important;min-height: 30px;">
      <router-link :to="{name: 'root'}" class="navbar-brand">
        <i :class="flagClass"></i>
        {{apps}}
      </router-link>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="container-fluid" >
            <div class="row">
              <div style="height:40px">&nbsp;</div>
              <div class="col-md-12">
                <div id="mainPosAlert" v-bind:class="alert.type" class="alert alert-dismissible" role="alert" style="display: none">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <span class="message">{{ alert.message }}</span>
              </div>
            </div>
            <div class="col-md-8">
              <b-card no-body>
                <b-tabs ref="tabs" pills card v-model="tabStep">
                  <b-tab :title="labelTabCategories">
                    <pos-tab-category
                      :till-id="this.till.id"
                      :select-category="selectCategory"
                      :is-caddy-items-loader="isCaddyItemsLoader"
                      :set-caddy-items-loader="setCaddyItemsLoader">
                    </pos-tab-category>
                  </b-tab>
                  <b-tab ref="tab-article" :title="labelTabArticles" :disabled="tabArticlesDisabled">
                    <pos-tab-article
                      :till-id="this.till.id"
                      :load-till="loadTill"
                      ref="tab-article-component"
                      :is-caddy-items-loader="isCaddyItemsLoader"
                      :set-caddy-items-loader="setCaddyItemsLoader">
                    </pos-tab-article>
                  </b-tab>
                  <b-tab :title="labelTabCustomers">
                    <pos-tab-customer
                      :till="this.till"
                      :till-id="this.till.id"
                      :load-till="loadTill">
                    </pos-tab-customer>
                  </b-tab>
                  <b-tab :title="labelTabSales">
                    <pos-tab-sales
                      :till="this.till"
                      :load-till="loadTill">
                    </pos-tab-sales>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
            <div class="col-md-4">
              <pos-tab-caddy
                :till-id="this.till.id"
                :external-loading="showOverlayCaddyItems"
                :is-caddy-items-loader="isCaddyItemsLoader"
                :set-caddy-items-loader="setCaddyItemsLoader">
              </pos-tab-caddy>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {BCard, BTabs, BTab} from 'bootstrap-vue';
  import PosTopBar from "./Pos/PosTopBar";
  import PosTabCaddy from "./Pos/Tab/PosTabCaddy";
  import PosTabArticle from "./Pos/Tab/PosTabArticle";
  import PosTabCustomer from "./Pos/Tab/PosTabCustomer";
  import PosTabSales from "./Pos/Tab/PosTabSales";
  import PosTabCategory from "./Pos/Tab/PosTabCategory";
  import store from '../store'

  export default {
    components: {
      PosTabSales,
      PosTabCustomer,
      PosTabArticle,
      PosTabCategory,
      PosTabCaddy,
      PosTopBar,
      'b-card': BCard,
      'b-tab': BTab,
      'b-tabs': BTabs,
    },
    name: 'pos',
    data () {
      return {
        alert: {},
        tabStep: 0,
        showOverlayCaddyItems: false,
        apps: null,
        user: {},
        factory: {},
        category: null,
        till: {
            id: null,
            customer: {
                name: null,
            }
        },
        taxes: [],
        menus: [],
      };
    },
    mounted () {
      if (this.$route.params.tillId) {
          this.loadTill();
          this.loadData();
      } else {
          this.findTill();
      }
    },
    computed: {
      flagClass : function() {
          return 'flag-icon flag-icon-be';
      },
      labelTabCategories: function () {
          return 'Categories';
      },
      labelTabArticles: function () {
          if (this.category) {
              return 'Category: '+ this.category.name;
          }
          return 'Articles';
      },
      labelTabCustomers: function () {
          return 'Customers';
      },
      labelTabSales: function () {
          return 'Sales';
      },
      tabArticlesDisabled: function () {
          return this.category === null;
      }
    },
    methods: {
      selectCategory: function (category) {
          let tabArticleComponent = this.$refs['tab-article-component'];
          if (typeof tabArticleComponent.loadArticles === 'function') {
              this.category = category;
              tabArticleComponent.loadArticles(category);
              this.tabStep = 1;
          }
      },
      findTill : function() {
        this.$http.get('pos/till').then((response) => {
            if (response.ok === true) {
                this.$router.push({name: 'pos', params: {tillId: response.data.id}});
            }
        });
      },
      loadTill : function() {
          this.showOverlayCaddyItems = true;
          this.$http.get('till/'+this.$route.params.tillId).then((response) => {
            this.showOverlayCaddyItems = false;
              if (response.ok === true) {
                  this.till = response.data;
                  if (!this.till.customer) {
                      this.till.customer = {
                          id: null,
                          name: null
                      };
                  }
              }
          });
      },
      loadData : function () {
          this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/tax/vat_rate').then((response) => {
              if (response.ok === true) {
                  this.taxes = response.data;
              }
          });
      },
      setCaddyItemsLoader: function (value) {
          this.showOverlayCaddyItems = value === true;
      },
      isCaddyItemsLoader: function () {
          return this.showOverlayCaddyItems;
      }
    }

  }
</script>
